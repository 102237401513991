import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`

const Overlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	background: rgba(255, 255, 255, 0.8);
	z-index: 11;
`

const Loader = styled.div`
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(0, 159, 222, 0.2);
	border-right: 1.1em solid rgba(0, 159, 222, 0.2);
	border-bottom: 1.1em solid rgba(0, 159, 222, 0.2);
	border-left: 1.1em solid ${props => props.theme.colors.primary};
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: ${rotate} 1.1s infinite linear;
	animation: ${rotate} 1.1s infinite linear;
	&,
	&:after {
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
`
function Loading() {
	return (
		<Overlay>
			<Loader />
		</Overlay>
	)
}

export default Loading
