import React from 'react'
import styled from 'styled-components'

const MediaQueryDesk = styled.div`
	@media (max-width: 1023.98px) {
		display: none;
	}
`

const MediaQueryMobile = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}
`

const MediaQuery = ({ minDeviceWidth, children }) =>
	minDeviceWidth ? <MediaQueryDesk>{children}</MediaQueryDesk> : <MediaQueryMobile>{children}</MediaQueryMobile>

export default MediaQuery
