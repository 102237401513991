import React, { useState } from 'react'
import BannerLandingPage from '@monorepo-amais/commons/components/LandingPage/BannerLandingPage'
import SecaoLandingPage from '@monorepo-amais/commons/components/LandingPage/SecaoLandingPage'
import ServicebarLandingPage from '@monorepo-amais/commons/components/LandingPage/ServicebarLandingPage'
import AgendamentoLandingPage from '@monorepo-amais/commons/components/LandingPage/AgendamentoLandingPage'
import BeneficiosLandingPage from '@monorepo-amais/commons/components/LandingPage/BeneficiosLandingPage'
import RegioesLandingPage from '@monorepo-amais/commons/components/LandingPage/RegioesLandingPage'
import FixedBarLandingPage from '@monorepo-amais/commons/components/LandingPage/FixedBarLandingPage'
import Layout from './../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

import { Loading } from '@monorepo-amais/commons/components/Search'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const AtendimentoMovel = ({ data }) => {
	const [loading, setLoading] = useState(false)

	return (
		<Layout footer={data.footer} regionais={data.regionais}>
			<SEO
				title={data.cosmicjsLandingPages.metadata.seo.titulo}
				description={data.cosmicjsLandingPages.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(data.cosmicjsLandingPages.metadata.seo.imagem.imgix_url)}
			/>
			{loading && <Loading />}
			<BannerLandingPage {...data} />
			<SecaoLandingPage {...data} />
			<ServicebarLandingPage {...data} />
			<BeneficiosLandingPage {...data} />
			<RegioesLandingPage {...data} popupMessage={'a+ até você'} />
			<AgendamentoLandingPage {...data} setLoading={setLoading} />
			<FixedBarLandingPage {...data} />
		</Layout>
	)
}

export const query = graphql`
	query {
		cosmicjsLandingPages {
			slug
			title
			metadata {
				cep
				emails {
					email
				}
				banner {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				barra_de_informacoes {
					texto
					icone {
						imgix_url
						url
					}
				}
				barra_fixa {
					link_botao
					texto
					texto_botao
				}
				formulario {
					botao_texto
					titulo
				}
				secao2 {
					descricao
					carrossel {
						texto
						imagem {
							imgix_url
							url
						}
					}
					titulo
				}
				secao3 {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				secao4 {
					titulo
					itens {
						botao_link
						botao_texto
						descricao
						titulo
						icone {
							imgix_url
							url
						}
					}
				}
				seo {
					descricao
					descricao_imagem
					titulo
					imagem {
						imgix_url
						url
					}
				}
				secao1 {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
			}
		}
	}
`

export default AtendimentoMovel
