import React, { useState } from 'react'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Select from 'react-select'
import InputMask from 'react-input-mask'
import validator from 'validator'
import { useFormik } from 'formik'
import './react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import Mensagem from '../Mensagem/Mensagem'
import { Input, InputArea } from '../Inputs'
import isValidCPF from '../../utils/cpf'
import { CSSTransition } from 'react-transition-group'
import { sendEmail } from '../../api/email'
import { ptBR } from 'date-fns/esm/locale'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

registerLocale('pt-BR', ptBR)

const Row = styled.div`
	background-color: ${props => props.theme.colors.grayf8};
	padding: 10px 30px;

	@media (max-width: 767.98px) {
		padding: 40px 15px;
		margin-bottom: 20px;
	}

	@media (min-width: 768px) {
		margin-bottom: 40px;
		padding-bottom: 40px;
		padding-top: 40px;
	}
`

const Itens = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 40px;

	@media (max-width: 767.98px) {
		display: block;
	}
`

const Title = styled.p`
	position: relative;
	text-align: center;
	font-size: 40px;
	font-weight: bold;
	margin: 0;

	@media (max-width: 767.98px) {
		text-align: left;
		font-size: 28px;
		margin-top: 0;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 90px;
		height: 5px;
		left: 50%;
		transform: translateX(-50%);
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
			left: 0;
			transform: translateX(0);
		}
	}
`

const ItemAgendamento = styled.div`
	width: 20%;
	margin: 0 5%;

	@media (max-width: 767.98px) {
		display: flex;
		align-items: center;
		width: 90%;
	}
`

const Icon = styled.div`
	max-width: 100px;
	max-height: 100px;
	margin: 0 auto;

	@media (max-width: 767.98px) {
		width: 30%;
		max-height: 70px;
		margin: 0 15px 0;
	}
`

const Img = styled.img`
	width: 100px;
	height: 100px;
	object-fit: scale-down;

	@media (max-width: 767.98px) {
		width: 80px;
		height: 80px;
	}
`

const Text = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - 100px);

	@media (max-width: 767.98px) {
		width: 70%;
	}
`

const TitleItem = styled.p`
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	color: ${props => props.theme.colors.primary};

	@media (max-width: 767.98px) {
		text-align: left;
	}
`

const DescItem = styled.div`
	text-align: center;
	font-size: 16px;
	color: ${props => props.theme.colors.gray66};

	p {
		margin: 0 0 20px;
	}

	@media (max-width: 767.98px) {
		text-align: left;
	}
`

const LinkDecoration = styled.a`
	width: 100%;
	align-self: baseline;
	margin-top: auto;
	text-decoration: none;
`

const Btn = styled.button`
	cursor: pointer;
	border: none;
	background-color: ${props => props.theme.colors.primary};
	text-decoration: none;
	color: ${props => props.theme.colors.white};
	position: relative;
	height: auto;
	padding: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	font-size: 12px;
	width: 80%;
	margin: 0 auto;
	outline: unset;

	@media (max-width: 767.98px) {
		margin: 0;
	}
`

const FormDiv = styled.div`
	@media (max-width: 767.98px) {
		padding: 15px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 85%;
		margin: 0 auto;
	}
`

const Form = styled.form`
	width: 75%;
	margin: 10px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 991.98px) {
		width: 100%;
		float: none;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		width: 80%;
	}
`

const SelectLabel = styled.span`
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #b9b9b9;
	width: 100%;
	display: flex;
`

const PeriodoSelect = styled.div`
	margin: 20px 0;
	width: 20%;

	@media (max-width: 767.98px) {
		margin: 15px 0px;
		width: 100%;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 23%;
	}
`

const ReturnDiv = styled.div`
	margin: 20px 0;
	width: 20%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: 767.98px) {
		width: 100%;
		justify-content: flex-start;
		margin: 15px 0;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 27%;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		width: 24%;
	}
`

const CPF = styled.div`
	width: 20%;
	margin: 20px 0;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}
`

const FullName = styled.div`
	width: 35%;
	margin: 20px 0;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}
`

const Convenio = styled.div`
	margin: 20px 0;
	width: 30%;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}
`

const Empresa = styled.div`
	margin: 20px 0;
	width: 43%;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}

	@media (min-width: 768px) and (max-width: 1199.98px) {
		width: 40%;
	}
`

const Phone = styled.div`
	margin: 20px 0;
	width: 20%;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 18%;
	}
`

const DateDiv = styled.div`
	margin: 20px 0;
	width: 30%;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 35%;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}
`

const Cep = styled.div`
	margin: 20px 0;
	width: 20%;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 18%;
	}
`

const Email = styled.div`
	width: 35%;
	margin: 20px 0;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 15px 0;
	}
`

const Observation = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-top: 31px;
	width: 100%;
	display: flex;
`

const ButtonDiv = styled.div`
	margin: 30px auto;
	text-align: center;

	@media (max-width: 767.98px) {
		width: 100%;
	}
`

const Button = styled.button`
	font-size: 16px;
	border-radius: 100px;
	width: 344px;
	height: 56px;
	background-color: ${props => props.theme.colors.primary};
	color: white;
	padding: 0.5rem 1rem;
	margin: 0;
	border: none;
	white-space: pre-wrap;
	cursor: pointer;

	&:not([disabled]):hover {
		background: #2eb0e4;
		border-color: #2eb0e4;
	}

	&:not([disabled]):active {
		background: #2eb0e4;
		border-color: #2eb0e4;
	}

	@media (max-width: 767.98px) {
		width: 100%;
	}
`

const InvalidInput = styled.span`
	position: absolute;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: #ff0000;
	margin: 5px 0px 0px;
`

const Required = styled.span`
	color: ${props => props.theme.colors.primary};
`

const Wrapper = styled.div`
	height: auto;
	padding: 0px 16px 0 0;
	box-sizing: border-box;
	display: block;
	float: left;

	@media (max-width: 767.98px) {
		padding: 0px 48px 0 0;
	}
`

const Item = styled.div`
	display: flex;
	align-items: center;
	height: 48px;
	position: relative;
`

const RadioButtonLabel = styled.label`
	position: absolute;
	top: 25%;
	left: 4px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: white;
	border: 1px solid #bebebe;
`

const RadioButtonText = styled.span`
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #6d6d6d;
`

const RadioButton = styled.input`
	opacity: 0;
	z-index: 1;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin-right: 10px;
	&:hover ~ ${RadioButtonLabel} {
		background: white;
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin: 4px;
			background: ${props => props.theme.colors.grayb9};
		}
	}
	&:checked + ${RadioButtonLabel} {
		background: white;
		border: 1px solid ${props => props.theme.colors.grayb9};
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin: 4px;
			box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
			background: ${props => props.theme.colors.grayb9};
		}
	}
`

const TransitionDiv = styled.div`
	.my-node-enter {
		max-height: 0;
	}
	.my-node-enter-active {
		max-height: 500px;
		/* padding: 15px; */
		overflow: hidden;
		transition-property: all;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}
	.my-node-exit {
		max-height: 500px;
		/* padding: 15px; */
		overflow: hidden;
		transition-property: all;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}
	.my-node-exit-active {
		max-height: 0;
	}
`

const DatePickerWrapper = styled.div`
	display: block;
`

const DatePickerStyle = styled(DatePicker)`
	font-size: 16px;
	color: ${props => props.theme.colors.gray66};
	height: 24px;
	border: none;
	border-bottom: 1px solid #ddd;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: normal;
	text-align: left;
	position: relative;
	display: block;
	width: 100%;
	margin-top: 12px;
	background: linear-gradient(to top, #009fde 50%, #009fde 50%);
	background-position: left bottom;
	background-size: 0 1px;
	background-repeat: no-repeat;
	padding: 0px 10px 10px 0px;
	transition: all 0.2s ease-in-out;
	outline: none;
	width: 100%;

	&::placeholder {
		color: ${props => props.theme.colors.grayb9};
		font-family: 'fs_lolaregular';
	}
`

const InputPhone = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='(99) 99999-9999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('phone')}
	>
		{inputProps => (
			<Input
				id='phone'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label='Telefone'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor='#666666'
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)

const InputCep = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='99999-999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('cep')}
	>
		{inputProps => (
			<Input
				id='cep'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label='CEP'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor='#666666'
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)
const InputCpf = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='999.999.999-99'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('cpf')}
	>
		{inputProps => (
			<Input
				id='cpf'
				value={value}
				error={error && touched}
				onChange={onChange}
				label='CPF'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
				{...inputProps}
			/>
		)}
	</InputMask>
)

const selectStyles = {
	indicatorSeparator: base => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: base => ({
		...base,
		svg: {
			fill: '#009fde'
		}
	}),
	control: base => ({
		...base,
		borderBotton: '1px',
		borderTop: '0px',
		borderLeft: '0px',
		borderRight: '0px',
		borderRadius: '0px',
		backgroundColor: '#ffffff',
		borderColor: '#e2e2e2',
		boxShadow: 'none',
		'&:hover': {
			borderColor: '#e2e2e2'
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		textAlign: 'center',
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		color: '#666666',
		fontSize: '20px',
		lineHeight: '19px'
	}),
	placeholder: base => ({
		...base,
		color: '#666666',
		fontSize: '20px',
		lineHeight: '19px',
		left: '0px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '20px',
		lineHeight: '19px',
		backgroundColor: state.isSelected ? '#009fde' : '#ffffff',
		color: state.isSelected ? '#ffffff' : '#666666',
		':active': {
			backgroundColor: state.isSelected ? '#009fde' : '#ffffff',
			color: state.isSelected ? '#ffffff' : '#666666'
		},
		'&:hover': {
			backgroundColor: '#009fde',
			color: '#ffffff'
		}
	})
}

function hideMessage(submitSuccess, setShowMessage, resetForm) {
	setShowMessage(false)

	if (submitSuccess) resetForm()
}

const periodos = [
	{ value: 'ma', label: 'Manhã' },
	{ value: 'ta', label: 'Tarde' },
	{ value: 'no', label: 'Noite' }
]

export default function AgendamentoLandingPage({ cosmicjsLandingPages, setLoading }) {
	const [secondCollapseOpened, setSecondCollapseOpen] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageContent, setMessageContent] = useState('')
	const [submitSuccess, setSubmitSuccess] = useState(false)

	const handleOpenSecondCollapse = () => {
		setSecondCollapseOpen(!secondCollapseOpened)
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			phone: '',
			date: '',
			periodo: null,
			cep: '',
			cpf: '',
			convenio: '',
			empresa: '',
			hasHealthInsurance: true
		},
		validate: values => {
			const err = {}
			const message = 'Campo obrigatório'
			const invalid = 'Campo inválido'
			const phoneCheck = values.phone
				.replace('(', '')
				.replace(')', '')
				.replace(' ', '')
				.replace('-', '')

			if (!values.name) err.name = message
			if (!values.email) err.email = message
			if (!validator.isEmail(values.email) && values.email) err.email = invalid
			if (!phoneCheck) err.phone = message
			if (phoneCheck && phoneCheck.length < 10) err.phone = invalid
			if (!values.date) err.date = message
			if (!values.periodo) err.periodo = message
			if (!values.cep) err.cep = message
			if (!values.convenio && formik.values.hasHealthInsurance === true) err.convenio = message
			if (!values.empresa) err.empresa = message
			// if (!values.hasHealthInsurance) err.hasHealthInsurance = message
			if (!values.cpf) err.cpf = message
			if (
				!isValidCPF(
					values.cpf
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				) &&
				values.cpf
			)
				err.cpf = invalid

			return err
		},
		onSubmit: values => {
			const agendamento = {
				name: values.name,
				cpf: values.cpf.replace(/[^0-9]+/g, ''),
				email: values.email,
				phone: values.phone,
				scheduleDate: values.date,
				contactPeriod: values.periodo.label,
				zipCode: values.cep,
				healthInsurance: values.convenio,
				company: values.empresa,
				hasHealthInsurance: values.hasHealthInsurance
			}

			setLoading(true)

			sendEmail('pre-schedule', agendamento)
				.then(response => {
					if (response.status === 202) {
						setSubmitSuccess(true)
						setMessageContent('Mensagem enviada com sucesso')
					}
					setLoading(false)
					setShowMessage(true)
				})
				.catch(err => {
					setSubmitSuccess(false)
					setMessageContent('Falha ao tentar enviar a mensagem!')
					setLoading(false)
					setShowMessage(true)
				})
		}
	})

	const handleBlur = field => {
		formik.setTouched({ ...formik.touched, [field]: true })
	}

	const phoneProps = {
		value: formik.values.phone,
		error: formik.errors.phone,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.phone
	}

	const cepProps = {
		value: formik.values.cep,
		error: formik.errors.cep,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.cep
	}

	const cpfProps = {
		value: formik.values.cpf,
		error: formik.errors.cpf,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.cpf
	}

	const scheduleOptions = cosmicjsLandingPages.metadata.secao4.itens.map((item, index) => {
		let actionButton = null

		if (item.botao_link.includes('tel:')) {
			actionButton = (
				<LinkDecoration href={item.botao_link}>
					<Btn>{item.botao_texto}</Btn>
				</LinkDecoration>
			)
		} else if (item.botao_link) {
			actionButton = (
				<LinkDecoration href={item.botao_link} target='_blank'>
					<Btn>{item.botao_texto}</Btn>
				</LinkDecoration>
			)
		} else {
			actionButton = (
				<LinkDecoration onClick={handleOpenSecondCollapse}>
					<Btn>{item.botao_texto}</Btn>
				</LinkDecoration>
			)
		}
		return (
			<ItemAgendamento key={item.titulo}>
				<Icon>
					<Img src={imgixUrlOptimizerAuto(item.icone.imgix_url)} />
				</Icon>
				<Text>
					<TitleItem id={`title-scheduling-lading-item-${index}`}>{item.titulo}</TitleItem>
					<DescItem dangerouslySetInnerHTML={{ __html: item.descricao }}></DescItem>
					{actionButton}
				</Text>
			</ItemAgendamento>
		)
	})

	return (
		<>
			<Row id='agendamentoLanding'>
				<Title id='title-scheduling-lading'>{cosmicjsLandingPages.metadata.secao4.titulo}</Title>
				<Itens>{scheduleOptions}</Itens>
			</Row>
			<TransitionDiv>
				<CSSTransition in={secondCollapseOpened} timeout={350} classNames='my-node' unmountOnExit appear>
					<FormDiv>
						<Title>{cosmicjsLandingPages.metadata.formulario.titulo}</Title>
						<Form onSubmit={formik.handleSubmit}>
							<FullName>
								<Input
									id='name'
									label='Nome'
									required={true}
									lineHeight='15px'
									fontSize='16px'
									color='#b9b9b9'
									inputFontSize='14px'
									inputColor='#666666'
									inputPadding='padding: 0px 10px 11px 0px;'
									inputBorderBottom='1px solid #ddd'
									inputMarginTop='-34px'
									inputBackground='transparent'
									onChange={formik.handleChange}
									value={formik.values.name}
									error={formik.touched.name && formik.errors.name}
									onBlur={() => handleBlur('name')}
								/>
								{formik.touched.name && formik.errors.name && <InvalidInput>{formik.errors.name}</InvalidInput>}
							</FullName>
							<Email>
								<Input
									id='email'
									label='E-mail'
									required={true}
									lineHeight='15px'
									fontSize='16px'
									color='#b9b9b9'
									inputFontSize='14px'
									inputColor='#666666'
									inputPadding='padding: 0px 10px 11px 0px;'
									inputBorderBottom='1px solid #ddd'
									inputMarginTop='-34px'
									inputBackground='transparent'
									onChange={formik.handleChange}
									value={formik.values.email}
									error={formik.touched.email && formik.errors.email}
									onBlur={() => handleBlur('email')}
								/>
								{formik.touched.email && formik.errors.email && <InvalidInput>{formik.errors.email}</InvalidInput>}
							</Email>
							<CPF>
								<InputCpf {...cpfProps} />
								{formik.touched.cpf && formik.errors.cpf && <InvalidInput>{formik.errors.cpf}</InvalidInput>}
							</CPF>
							<Phone>
								<InputPhone {...phoneProps} />
								{formik.touched.phone && formik.errors.phone && <InvalidInput>{formik.errors.phone}</InvalidInput>}
							</Phone>
							<DateDiv>
								<DatePickerWrapper>
									<DatePickerStyle
										id='date'
										selected={formik.values.date}
										onChange={date => formik.setFieldValue('date', date)}
										placeholderText='Qual data gostaria agendar?*'
										dateFormat='dd/MM/yyyy'
										locale='pt-BR'
										onBlur={() => handleBlur('date')}
									/>
								</DatePickerWrapper>
								{formik.touched.date && formik.errors.date && <InvalidInput>{formik.errors.date}</InvalidInput>}
							</DateDiv>
							<PeriodoSelect>
								<SelectLabel>
									Melhor periodo para contato
									<Required>*</Required>
								</SelectLabel>
								<Select
									styles={selectStyles}
									options={periodos}
									placeholder='Selecione'
									value={formik.values.periodo}
									onChange={periodo => formik.setFieldValue('periodo', periodo)}
									error={formik.touched.periodo && formik.errors.periodo}
									onBlur={() => handleBlur('periodo')}
								/>
								{formik.touched.periodo && formik.errors.periodo && (
									<InvalidInput>{formik.errors.periodo}</InvalidInput>
								)}
							</PeriodoSelect>
							<Cep>
								<InputCep {...cepProps} />
								{formik.touched.cep && formik.errors.cep && <InvalidInput>{formik.errors.cep}</InvalidInput>}
							</Cep>
							<ReturnDiv>
								<SelectLabel>
									Possui convênio?
									<Required>*</Required>
								</SelectLabel>
								<Wrapper>
									<Item>
										<RadioButton
											type='radio'
											name='radio'
											checked={formik.values.hasHealthInsurance === true}
											value={formik.values.hasHealthInsurance}
											onChange={() => formik.setFieldValue('hasHealthInsurance', true)}
										/>
										<RadioButtonLabel />
										<RadioButtonText>Sim</RadioButtonText>
									</Item>
								</Wrapper>
								<Wrapper>
									<Item>
										<RadioButton
											type='radio'
											name='radio'
											checked={formik.values.hasHealthInsurance === false}
											value={formik.values.hasHealthInsurance}
											onChange={() => formik.setFieldValue('hasHealthInsurance', false)}
										/>
										<RadioButtonLabel />
										<RadioButtonText>Não</RadioButtonText>
									</Item>
								</Wrapper>
								{formik.errors.hasHealthInsurance && <InvalidInput>{formik.errors.hasHealthInsurance}</InvalidInput>}
							</ReturnDiv>
							<Convenio>
								<InputArea
									id='convenio'
									label='Qual convênio?'
									required={formik.values.hasHealthInsurance === true}
									lineHeight='15px'
									fontSize='16px'
									color='#b9b9b9'
									inputFontSize='14px'
									inputColor='#666666'
									inputPadding='padding: 0px 10px 11px 0px;'
									inputBorderBottom='1px solid #ddd'
									inputMarginTop='-34px'
									inputBackground='transparent'
									onChange={formik.handleChange}
									value={formik.values.convenio}
									error={formik.touched.convenio && formik.errors.convenio}
									onBlur={() => handleBlur('convenio')}
								/>
								{formik.touched.convenio && formik.errors.convenio && (
									<InvalidInput>{formik.errors.convenio}</InvalidInput>
								)}
							</Convenio>
							<Empresa>
								<InputArea
									id='empresa'
									label='Qual empresa'
									required={true}
									lineHeight='15px'
									fontSize='16px'
									color='#b9b9b9'
									inputFontSize='14px'
									inputColor='#666666'
									inputPadding='padding: 0px 10px 11px 0px;'
									inputBorderBottom='1px solid #ddd'
									inputMarginTop='-34px'
									inputBackground='transparent'
									onChange={formik.handleChange}
									value={formik.values.empresa}
									error={formik.touched.empresa && formik.errors.empresa}
									onBlur={() => handleBlur('empresa')}
								/>
								{formik.touched.empresa && formik.errors.empresa && (
									<InvalidInput>{formik.errors.empresa}</InvalidInput>
								)}
							</Empresa>
							<Observation>
								<Required>* </Required> Campos Obrigatórios
							</Observation>
							<ButtonDiv>
								<Button type='submit'>{cosmicjsLandingPages.metadata.formulario.botao_texto}</Button>
							</ButtonDiv>
						</Form>
						{showMessage && (
							<Mensagem
								closeMessage={() => hideMessage(submitSuccess, setShowMessage, formik.resetForm)}
								textoBotao='OK'
								mensagem={messageContent}
								success={submitSuccess}
							/>
						)}
					</FormDiv>
				</CSSTransition>
			</TransitionDiv>
		</>
	)
}
