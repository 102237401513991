const titlefy = (slug = '') => {
	var words = slug.split('-')
	return words
		.map(function(word) {
			return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
		})
		.join(' ')
}

export default titlefy
