import styled from 'styled-components'
import React from 'react'
import { theme } from 'styled-tools'
import { Link } from 'gatsby'
import { IoIosArrowRoundForward } from 'react-icons/io'
import titlefy from '@monorepo-amais/commons/components/Search/titlefy'
import { Separator } from '@monorepo-amais/commons/components/Blocks'
import { BUCKET_LIST } from '@monorepo-amais/commons/utils/enums'

// import slugify from '@monorepo-amais/commons/utils/slugify'
// import Sinonimias from '../exames/Sinonimias'

// import { formataDataCosmic } from '@monorepo-amais/commons/utils/date'

const CardContainer = styled.div`
	padding: 20px;
	border-radius: 3px;
	border: 1px solid ${theme('colors.graye2')};
	margin: 0 auto 15px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;

	@media (max-width: 1024px) {
		width: calc(90% - 30px);
		padding: 20px 15px 15px;
	}
`

const CardLink = styled(Link)`
	@media (max-width: 767.98px) {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`

const CardContent = styled.div`
	width: 100%;
`

const Category = styled(Link)`
	text-decoration: none;
	color: ${theme('colors.primary')};
	font-size: 14px;
	text-transform: uppercase;
	display: inline-block;
	margin-bottom: 10px;
	transition: 0.2s all ease-in;
	&:hover {
		color: ${theme('colors.primary_hover')};
	}
`

const Title = styled.h3`
	color: ${theme('colors.black_fonts')};
	font-size: 20px;
	font-weight: bold;
	margin: 0 0 10px 0;

	@media (max-width: 767.98px) {
		font-size: 18px;
		font-weight: normal;
	}
`

const Text = styled.div`
	font-size: 14px;
	color: #77787b;
	margin: 0 0 20px 0;
	line-height: 19px;
`

const MaisInfosA = styled(Link)`
	font-size: 14px;
	color: ${theme('colors.primary')};
	text-decoration: none;
	transition: 0.2s all ease-in;
	margin-left: auto;
	display: flex;
	align-items: center;
	&:hover {
		color: ${theme('colors.primary_hover')};
	}

	svg {
		width: 40px;
		height: 40px;
	}
`

const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 767.98px) {
		display: none;
	}
`

/**
 * Gerar os links baseado nas regras de negócio e parametrização do cosmic.
 * @param {*} elasticNode nó recebido para renderização
 */

const string_to_slug = str => {
	str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	str = str.replace(/^\s+|\s+$/g, '') // trim
	str = str.toLowerCase()

	// remove accents, swap ñ for n, etc
	var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
	var to = 'aaaaeeeeiiiioooouuuunc------'
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
	}

	str = str
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-') // collapse dashes

	return str
}

const elasticLink = elasticNode => {
	const index = sanitizeIndex(elasticNode._index)
	// const hasMetadata = elasticNode._source.metadata

	// Boletins vão para o primeiro artigo do boletim
	// const isBoletim = index === 'boletins-medicos'
	// // Revistas vão apenas para /revistas
	// const isRevista = index === 'revistas'
	// // páginas vão para o slug cadastrado
	// const isPagina = index === 'paginas'
	// // convenios vão para /convenios
	// const isConvenio = index === 'convenios'

	if (index === 'unidades') {
		return `/${index}/${string_to_slug(elasticNode._source.name)}`
	}

	if (index === 'convenios') {
		return `/${index}`
	}

	return `/${index}/${elasticNode._source.slug}`

	// if (isBoletim) return `/boletim-medico/${slugify(elasticNode._source.metadata.destaques[0].titulo)}`
	// else if (isRevista || isConvenio) return `/${elasticNode._source.type_slug}/`
	// else if (hasMetadata) return `/${elasticNode._source.type_slug}/${elasticNode._source.slug}`
	// else if (isPagina) return `/${elasticNode._source.slug}/`
}

/**
 * Gerar o link da categoria do card baseado nas regras de negócio e parametrização do cosmic.
 * @param {*} elasticNode nó recebido para renderização
 */
const elasticCategoryLink = elasticNode => {
	const isPaginaNode = sanitizeIndex(elasticNode._index) === 'paginas'

	return isPaginaNode ? elasticNode._source.slug : sanitizeIndex(elasticNode._index)
}

/**
 * Gerar o titulo card baseado nas regras de negócio e parametrização do cosmic.
 * @param {*} elasticNode nó recebido para renderização
 */
const elasticTitle = elasticNode =>
	// const isConvenio = elasticNode._source.type_slug === 'convenios'

	// return isConvenio ? elasticNode._source.metadata.descricao : elasticNode._source.title

	elasticNode._source.name ? elasticNode._source.name : elasticNode._source.title

/**
 * Gera o subtitulo do card baseado nas regras de negócio e parametrização do cosmic.
 * Diferente das outras funções, essa retorna um componente
 * @param {*} elasticNode nó recebido para renderização
 */
const elasticSubtitle = elasticNode => {
	// Exames retornam as sinonímias
	// const isExame = elasticNode._source.type_slug === 'exames'
	// const isNoticias = elasticNode._source.type_slug === 'noticias'
	// const isBoletim = elasticNode._source.type_slug === 'boletins-medicos'
	// const isUnidade = elasticNode._source.type_slug === 'unidades'
	// if (isExame) return <Sinonimias array={elasticNode._source.metadata.sinonimias} />
	// else if (isBoletim)
	// 	return (
	// 		<Text>
	// 			ano {elasticNode._source.metadata.ano} • {formataDataCosmic(elasticNode._source.metadata.data)}
	// 		</Text>
	// 	)
	// else if (isNoticias)
	// 	return <Text dangerouslySetInnerHTML={{ __html: elasticNode._source.metadata.pequena_descricao }} />
	// else if (isUnidade) return <Text>{elasticNode._source.metadata.endereco}</Text>
	// else return <Text dangerouslySetInnerHTML={{ __html: elasticNode._source.content }} />

	const index = sanitizeIndex(elasticNode._index)

	if (index === 'convenios')
		return <Text dangerouslySetInnerHTML={{ __html: elasticNode._source.searchableTerms[0] }} />
	if (index === 'noticias')
		return (
			<Text
				dangerouslySetInnerHTML={{
					__html: elasticNode._source.searchableTerms[1]
						? elasticNode._source.searchableTerms[1]
						: elasticNode._source.searchableTerms[0]
				}}
			/>
		)
	if (index === 'exames')
		return <Text>{`${elasticNode._source.generalInstructions[0].content.substring(0, 250)}...`}</Text>
	if (index === 'unidades')
		return (
			<Text>{`${elasticNode._source.address}, ${elasticNode._source.neighborhood}, ${elasticNode._source.city}`}</Text>
		)
	else return <Text>{`${elasticNode._source.searchableTerms[1]}`}</Text>
}

const sanitizeIndex = string => {
	let str = string.replace(new RegExp('_', 'g'), '-').replace(`${process.env.GATSBY_COSMIC_BUCKET}-`, '')
	str = str.replace(str.substring(str.length - 14), '')

	return str
}

const SearchCard = elasticNode => {
	// const slug_type = ''
	const slug_type = BUCKET_LIST[sanitizeIndex(elasticNode._index)]

	// ****** ******
	// Filtro aplicado para exame 6983 para amais PI
	// após ajuste pelo back o filtro deverá ser retirado
	// ****** ******
	return process.env.COSMIC_BUCKET === 'amaispi' && elasticNode._id === '6983' ? null : (
		<CardContainer key={elasticNode._id}>
			{elasticLink(elasticNode) && <CardLink to={elasticLink(elasticNode)} />}
			{/* { props.image && <CardImage src={props.image} alt={props.title} /> } */}
			<CardContent>
				<Category to={elasticCategoryLink(elasticNode)}>{titlefy(slug_type || 'Páginas')}</Category>
				<Title id='title-search-card'>{elasticTitle(elasticNode)}</Title>
				{elasticSubtitle(elasticNode)}
				<Separator />
				<CardFooter>
					{/* { props.extraInfo && <ExtraInfo>{props.extraInfo}</ExtraInfo> } */}
					{/* {elasticNode._source.metadata && ( */}
					{elasticLink(elasticNode) && (
						<MaisInfosA to={elasticLink(elasticNode)}>
							Mais informações <IoIosArrowRoundForward />
						</MaisInfosA>
					)}
					{/* )} */}
				</CardFooter>
			</CardContent>
		</CardContainer>
	)
}

export default SearchCard
