import getBrandId from '../utils/getBrandId'
import BaseAxios from '@monorepo-amais/commons/utils/base-axios'
import axios from 'axios'

const emailConfig = {
	baseURL: 'https://l7tqp8zec7.execute-api.us-east-1.amazonaws.com/prd',
	headers: {
		'x-api-key': 'kHuaTJ4imp8vgVeqxa9Gr1h8MfnQP7aN2UBW4WMW',
		'Content-type': 'application/json'
	}
}

const emailAPI = axios.create(emailConfig)

export const sendEmail = async (formName, data) =>
	await emailAPI.post(`/${formName}`, {
		...(formName === 'contact-us' && { brandId: getBrandId(`${process.env.GATSBY_MS_MARCA_PARAM}`) }),
		...data
	})

export const sendMedicRegister = params => {
	const url = '/cadastro-medico/v1/medico'

	return BaseAxios.post(url, params, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
