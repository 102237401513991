import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import ShowModal from '../Modal'

import { Check } from 'styled-icons/boxicons-regular/Check'
import { ErrorAlt } from 'styled-icons/boxicons-regular/ErrorAlt'

const Layout = styled.div`
	position: absolute;
`

const CustomError = styled(ErrorAlt)`
	margin: 0 auto;
	height: 80px;
	color: red;
`

const CustomCheck = styled(Check)`
	margin: 0 auto;
	height: 80px;
	color: green;
`

// Styled Components
const MainContainer = styled.div`
	max-width: 1360px;
	padding: 0;
	margin: 0 auto;
	@media (min-width: 1024px) {
		padding: 0 15px;
	}
`

const Submit = styled.button`
	background: ${props => props.theme.colors.primary};
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	margin: 40px 0;
	width: 100%;
	border: none;
	color: ${theme('colors.white')};
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	&:focus,
	&:hover {
		background: ${props => props.theme.colors.primary_hover};
		outline: none;
	}
`

const SelectBox = styled.div`
	max-width: 292px;
	margin: 40px auto 0;
`

const Conteudo = styled.p`
	margin: 30px auto 20px;
	text-align: center;
	max-width: 292px;
	font-size: 16px;
	line-height: 16px;
	color: ${props => props.theme.colors.gray66};
`

const Mensagem = ({ textoBotao, mensagem, closeMessage, success }) => (
	<Layout>
		<MainContainer onClick={closeMessage}>
			<ShowModal>
				{!success && <CustomError />}
				{success && <CustomCheck />}
				<Conteudo>{mensagem}</Conteudo>
				<SelectBox>
					<Submit onClick={closeMessage}> {textoBotao} </Submit>
				</SelectBox>
			</ShowModal>
		</MainContainer>
	</Layout>
)

export default Mensagem
