import React from 'react'
import titlefy from './titlefy'
import { CheckboxContainer, CheckBox, CheckboxSpan } from '../Inputs'

const Filters = props => (
	<>
		{Object.entries(props.categories).map(([key, val]) => (
			<CheckboxContainer key={`checkbox-item-${key}`}>
				<div>
					<CheckBox type='checkbox' name={key} onChange={props.updateFilters} id={key} />
					<label htmlFor={key}> {titlefy(key)} </label>
				</div>
				<CheckboxSpan> ({val && val[0] && val[0].val}) </CheckboxSpan>
			</CheckboxContainer>
		))}
	</>
)

export default Filters
