import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import InputMask from 'react-input-mask'
import axios from '@monorepo-amais/commons/utils/base-axios'
import { FiSearch } from 'react-icons/fi'
import Mensagem from '../Mensagem/Mensagem'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media (max-width: 991.98px) {
		padding: 20px 15px;
	}
`

const Grid = styled.div`
	width: 85%;
	display: block;
	padding: 40px 10px;

	@media (max-width: 991.98px) {
		width: 100%;
	}
`

const Subtitle = styled.h3`
	margin: 0;
	position: relative;
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
		}
	}

	@media (max-width: 767.98px) {
		font-size: 28px;
	}
`

const Text = styled.div`
	padding-top: 40px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	@media (max-width: 991.98px) {
		line-height: 1.4;
		font-size: 15px;
		padding-top: 20px;
	}
`

const TextWrapper = styled.div`
	display: block;
	width: 100%;
	margin: 10px auto 50px;
	color: ${theme('colors.gray66')};
`

const SecaoImage = styled.div`
	width: 99%;
	display: block;

	@media (max-width: 767.98px) {
		width: 100%;
		margin: 0;
	}
`

const SearchWrapper = styled.form`
	position: relative;
	display: flex;
	justify-content: center;

	.icon {
		color: ${theme('colors.grayb8')};
		align-self: center;
		margin-left: -20px;
		/* z-index: 99999; */
		height: 45px;
		/* position: absolute; */
	}
`

const SearchInput = styled.input`
	&& {
		outline: none;
		border: none;
		border-bottom: 1px solid ${theme('colors.grayab')};

		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.28;
		letter-spacing: normal;
		text-align: left;
		width: 390px;
		color: ${theme('colors.gray3b')};
		padding: 0 5px;
		padding-right: 40px;

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${theme('colors.grayb5')};
			opacity: 1; /* Firefox */
		}
	}
`

const TextMessage = styled.span`
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	text-align: center;
	line-height: 1.86;
	letter-spacing: normal;
	.negrito {
		font-weight: bold;
	}

	.strong {
		font-size: 20px;
	}
`

const InputCep = ({ inputSearch, setInputSearch }) => (
	<InputMask
		mask='99999-999'
		maskChar={null}
		alwaysShowMask={false}
		value={inputSearch}
		onChange={e => setInputSearch(e.target.value)}
	>
		{inputProps => <SearchInput value={inputSearch} placeholder='Digite o seu CEP' />}
	</InputMask>
)

export default function RegioesLandingPage({ cosmicjsLandingPages, popupMessage }) {
	const [inputSearch, setInputSearch] = useState('')
	const [showMessage, setShowMessage] = useState(false)
	const [success, setSuccess] = useState(false)

	// const arrayCEP = cosmicjsLandingPages.metadata.cep.split(';')

	const fetchData = async cep => {
		try {
			let string = ''

			switch (process.env.GATSBY_MS_MARCA_PARAM) {
				case 'Amais SP':
					string = '?marca=AM&regional=SP'
					break
				case 'Amais PE':
					string = '?marca=AM&regional=PE'
					break
				case 'Amais PI':
					string = '?marca=AM&regional=PI'
					break
				case 'Amais PR':
					string = '?marca=AM&regional=PR'
					break
				case 'Diagnoson':
					string = '?marca=AM&regional=BA'
					break
				case 'Labs amais':
					string = '?marca=LA'
					break
				default:
					return ''
			}

			const data = await axios.get(`/agendar/v1/agendamento-movel/verificar-cep/${cep}${string}`)

			return data.data.cepAtendido
		} catch {
			return false
		}
	}

	const handleSearch = async e => {
		e.preventDefault()

		const cepToSearch = inputSearch.replace('-', '')
		// const hasCover = arrayCEP.includes(cepToSearch) || arrayCEP.includes(cepToSearch.substring(0, 5))

		const hasCover = await fetchData(cepToSearch)

		if (hasCover) {
			setSuccess(true)
			setShowMessage(true)
		} else {
			setSuccess(false)
			setShowMessage(true)
		}
	}

	const cepProps = {
		inputSearch: inputSearch,
		setInputSearch: setInputSearch
	}

	return (
		<Wrapper>
			<Grid>
				{showMessage && (
					<Mensagem
						closeMessage={() => setShowMessage(false)}
						textoBotao='OK'
						mensagem={
							<TextMessage>
								O CEP informado está{' '}
								<span className='negrito'>{`${success ? 'dentro' : 'fora'}`} da área de cobertura</span> do
								<span className='strong'> {popupMessage}!</span>
							</TextMessage>
						}
						success={success}
					/>
				)}
				<TextWrapper>
					<Subtitle>{cosmicjsLandingPages.metadata.secao3.titulo}</Subtitle>
					<Text dangerouslySetInnerHTML={{ __html: cosmicjsLandingPages.metadata.secao3.descricao }} />
				</TextWrapper>
				<SecaoImage>
					<SearchWrapper onSubmit={handleSearch}>
						<InputCep {...cepProps} />
						<FiSearch className='icon' onClick={handleSearch} />
					</SearchWrapper>
				</SecaoImage>
			</Grid>
		</Wrapper>
	)
}
