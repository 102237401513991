const amaisspIndexes =
	'amaissp-unidades,amaissp-exames,amaissp-noticias,amaissp-artigos,amaissp-revistas,amaissp-convenios'
const amaispeIndexes =
	'amaispe-unidades,amaispe-exames,amaispe-noticias,amaispe-artigos,amaispe-revistas,amaispe-convenios'
const amaispiIndexes = 'amaispi-unidades,amaispi-exames,amaispi-noticias,amaispi-convenios'
const amaisprIndexes =
	'amaispr-unidades,amaispr-exames,amaispr-noticias,amaispr-artigos,amaispr-revistas,amaispr-convenios'
const irnIndexes = 'irn-unidades,irn-exames,irn-noticias,irn-convenios'
const diagnosonIndexes = 'diagnoson-unidades,diagnoson-exames,diagnoson-noticias,diagnoson-convenios'
const labsamaisIndexes = 'labs-amais-unidades,labs-amais-exames,labs-amais-noticias,labs-amais-convenios'

// amaissp-boletins-medicos,

export const getIndexes = () => {
	switch (process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase()) {
		case 'amaissp':
			return amaisspIndexes
		case 'amaispe':
			return amaispeIndexes
		case 'amaispi':
			return amaispiIndexes
		case 'amaispr':
			return amaisprIndexes
		case 'irn':
			return irnIndexes
		case 'diagnoson':
			return diagnosonIndexes
		case 'labs-amais':
			return labsamaisIndexes
		default:
			return amaisspIndexes
	}
}
