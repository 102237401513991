import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../Buttons'

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	background: #f0f0f0;
	padding: 10px 0;
	position: fixed;
	bottom: 0;
	z-index: 10;

	@media (max-width: 767.98px) {
		justify-content: center;
	}
`

const Text = styled.div`
	font-size: 14px;
	padding: 0 15px;

	@media (max-width: 767.98px) {
		display: none;
	}
`

const Btn = styled(Button)`
	width: 290px;
	height: 45px;
	cursor: pointer;
	border: none;
	background-color: ${props => props.theme.colors.primary};
	text-decoration: none;
	color: ${props => props.theme.colors.white};
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	font-size: 16px;
	margin: 0;
	outline: unset;

	@media (max-width: 767.98px) {
		margin: 0 auto;
		height: 40px;
		font-size: 14px;
	}
`

const RowContent = styled.div`
	width: 85%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: space-between;
`

export default function FixedBarLandingPage({ cosmicjsLandingPages }) {
	const [fixedBarVisible, setFixedBarVisible] = useState(true)
	const referenceElementId = 'agendamentoLanding'
	const scrollToElementOffset = 40
	const hideElementOffset = 550

	// TODO: usar useRef para guardar a referência do elemento
	const getReferencePosition = () => document.getElementById(referenceElementId)?.getBoundingClientRect().top

	const goToAgendamento = () => {
		if (typeof window !== 'undefined') {
			const screenPosition = getReferencePosition() + window.scrollY
			window.scrollTo({
				top: screenPosition - scrollToElementOffset,
				left: 0,
				behavior: 'smooth'
			})
		}
	}

	const scrollPage = () => {
		const screenPosition = getReferencePosition() - hideElementOffset

		setFixedBarVisible(screenPosition > 0)
	}

	if (typeof window !== 'undefined') {
		window.addEventListener('scroll', scrollPage, { passive: true })
	}

	return (
		<>
			{fixedBarVisible && process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'amaissp' && (
				<Row>
					<RowContent>
						<Text>{cosmicjsLandingPages.metadata.barra_fixa.texto}</Text>
						<Btn onClick={goToAgendamento}>{cosmicjsLandingPages.metadata.barra_fixa.texto_botao}</Btn>
					</RowContent>
				</Row>
			)}
		</>
	)
}
