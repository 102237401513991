import React from 'react'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const BannerBackground = styled.div`
	background-image: url(${props => props.img});
	background-position: center;
	background-position-y: 25%;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 320px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 5px solid #edeff4;

	@media (max-width: 767.98px) {
		height: 230px;
		background-size: 130% 130%;
		background-position-y: center;
	}

	@media (max-width: 768px) {
		background-image: url(${props => props.mobile});
		background-size: 100% 100%;
	}

	@media (min-width: 1680px) {
		height: 400px;
		background-size: 130% 130%;
		background-position-y: center;
	}

	@media (min-width: 1880px) {
		height: 450px;
	}
`
const BannerText = styled.div`
	width: 85%;
	height: 75%;
	margin: 0 auto;

	@media (max-width: 767.98px) {
		padding-left: 15px;
		width: 100%;
	}
`

const BannerTitle = styled.h2`
	color: ${theme('colors.white')};
	width: 100%;
	height: 49px;
	font-size: 60px;
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
	line-height: 0.35;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.primary};
	margin-bottom: 0;

	@media (max-width: 767.98px) {
		font-size: 24px;
		height: 20px;
		margin-bottom: -10px;
	}
`

const BannerP = styled.div`
	color: ${theme('colors.black_fonts')};
	width: 100%;
	height: 87px;
	font-size: 22px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	margin-top: -15px;

	@media (max-width: 767.98px) {
		font-size: 14px;
		width: 190px;
	}
`

export default function BannerLandingPage({ cosmicjsLandingPages }) {
	return (
		<>
			<BannerBackground
				img={imgixUrlOptimizerAuto(cosmicjsLandingPages.metadata.banner.imagem.imgix_url, undefined, 320)}
				mobile={
					cosmicjsLandingPages.metadata.banner.mobile
						? imgixUrlOptimizerAuto(cosmicjsLandingPages.metadata.banner.imagem.imgix_url, undefined, 230)
						: imgixUrlOptimizerAuto(cosmicjsLandingPages.metadata.banner.imagem.imgix_url, undefined, 320)
				}
			>
				<BannerText>
					<BannerTitle>{cosmicjsLandingPages.metadata.banner.titulo}</BannerTitle>
					<BannerP dangerouslySetInnerHTML={{ __html: cosmicjsLandingPages.metadata.banner.descricao }} />
				</BannerText>
			</BannerBackground>
		</>
	)
}
