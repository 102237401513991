/**
 * Resultados da busca:
 * Página que recebe as funcionalidades de busca (ElasticSearch) e filtros
 *
 * Existem condicionais na exibição e funcionamento diferente dos filtros para
 * mobile e desktop. Exibição controlada com <MediaQuery> e com valores de window.width
 * As categorias estão sendo exibidas dinamicamente de acordo com o resultado da
 * função groupBy do lodash.
 *
 * TO DO: Verificar porque está executando novamente o render do componente
 * de filtros em qualquer atualização de estados.
 */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'

import groupBy from 'lodash.groupby'
import { FiSearch } from 'react-icons/fi'
import { BUCKET_LIST } from '@monorepo-amais/commons/utils/enums'
// import { URL_ELASTIC, BUCKET_LIST } from '@monorepo-amais/commons/utils/enums'
import { isMobile } from 'react-device-detect'
import { BreadcrumbAuto, Pagination, Separator } from '@monorepo-amais/commons/components/Blocks'
import sanitizeRegex from '@monorepo-amais/commons/utils/sanitizeRegex'
// import generateToken from '@monorepo-amais/commons/utils/generateToken'
import axios from '@monorepo-amais/commons/utils/base-axios'

/**
 * Componentes externos utilizados nesta página:
 */
import { Filters, Loading } from '@monorepo-amais/commons/components/Search'

import queryString from 'query-string'
import { MainTitle } from '@monorepo-amais/commons/components/Typography'

import { theme } from 'styled-tools'
import SearchCard from './SearchCard'
import useDebounce from '../../hooks/useDebounce'
import { getIndexes } from './brand-indexes'

/**
 * Estilos gerais dos componentes criados com styled-components:
 */
const MainContainer = styled.div`
	max-width: 1360px;
	padding: 0;
	margin: 0 auto;
	@media (min-width: 1024px) {
		padding: 0 20px;
	}
`

const Grid = styled.div`
	margin: 0 0 30px;
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: minmax(312px, 0.31fr) 1fr;
		grid-gap: 28px;
		grid-template-rows: auto;
		grid-template-areas: 'sidebar results';
		.results {
			grid-area: results;
			position: relative;
		}
	}
`

const Sidebar = styled.div`
	grid-area: sidebar;
	padding: 20px 10px 0;
	@media (min-width: 1024px) {
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		padding: 20px 10px;
		margin: 44px 0 0 0;
	}
`

const HelperText = styled.p`
	margin: 0 0 10px 0;
	color: #464646;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
`

const SearchForm = styled.form`
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid ${theme('colors.graye2')};
	border-radius: 5px;
	position: relative;
	overflow: hidden;
`

const SearchIcon = styled(FiSearch)`
	color: ${theme('colors.grayb8')};
	font-size: 25px;
	transition: 0.2s all ease-in;
`

const SearchButton = styled.button`
	cursor: pointer;
	border: none;
	background: none;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	outline: none;
	&:hover {
		${SearchIcon} {
			color: ${theme('colors.primary')};
		}
	}
`

const SearchInput = styled.input`
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	border: none;
	font-size: 18px;
	color: #464646;
	font-weight: 900;
	outline: none;
`

const FiltersWrapper = styled.div`
	@media (max-width: 1024px) {
		display: ${props => (props.open ? 'block' : 'none')};
		width: 100%;
		height: calc(100vh - 80px);
		position: fixed;
		background: ${theme('colors.white')};
		top: 80px;
		left: 0;
		z-index: 2;
		padding: 17px 24px;
		box-sizing: border-box;
	}
`

const FiltersFooter = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	.totalResults {
		padding: 6px 0;
		background: ${theme('colors.grayf1')};
		font-size: 14px;
		color: #464646;
		text-align: center;
		margin: 0;
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.toCancel,
	.toFilter {
		font-size: 14px;
		margin: 12px 24px;
		text-align: center;
		height: 47px;
		line-height: 47px;
		width: 160px;
		border: none;
		outline: none;
	}
	.toCancel {
		background: none;
		color: #464646;
		&:hover {
			color: ${theme('colors.gray66')};
		}
	}
	.toFilter {
		color: ${theme('colors.white')};
		background: ${theme('colors.primary')};
		border-radius: 47px;
		&:hover {
			background: ${theme('colors.primary_hover')};
		}
	}
`

const FilterActionsMobile = styled.div`
	background: ${theme('colors.grayf1')};
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	.results {
		font-size: 14px;
		color: #464646;
		margin: 0;
	}
	.filter {
		margin: 0;
		font-size: 15px;
		color: ${theme('colors.primary')};
		font-weight: 900;
		&:hover {
			color: ${theme('colors.primary_hover')};
		}
	}
`

const ResultsCounterDesktop = styled.h2`
	font-size: 20px;
	color: #464646;
	font-weight: 900;
	margin: 0 0 20px;
	@media (max-width: 1024px) {
		display: none;
	}
`

const NoResultsFound = styled.div`
	text-align: center;
	padding: 10px;
	.title {
		font-weight: 900;
		font-size: 25px;
		color: ${theme('colors.black33')};
		line-height: 30px;
		margin: 0 0 10px;
	}
	.text {
		font-size: 17px;
		line-height: 21px;
		color: ${theme('colors.gray66')};
	}
	@media (min-width: 1024px) {
		padding: 0;
		position: absolute;
		width: 60%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.title {
			font-size: 40px;
			line-height: 53px;
			margin: 0 0 20px;
		}
		.text {
			font-size: 24px;
			line-height: 31px;
		}
	}
`

const MainTitleWrapper = styled.div`
	@media (max-width: 1024px) {
		display: none;
	}
`

// const urlSearch = URL_ELASTIC(`/${process.env.COSMIC_BUCKET}*/_search`)

/**
 * Resultados da busca:
 * Página que recebe as funcionalidades de busca (ElasticSearch) e filtros
 *
 * Existem condicionais na exibição e funcionamento diferente dos filtros para
 * mobile e desktop. Exibição controlada com <MediaQuery> e com valores de window.width
 * As categorias estão sendo exibidas dinamicamente de acordo com o resultado da
 * função groupBy do lodash.
 *
 * TO DO: Verificar porque está executando novamente o render do componente
 * de filtros em qualquer atualização de estados.
 */
export default function Search({ location }) {
	/**
	 * Declaração de variavéis e definição de estados inicias:
	 */

	let searchParam = queryString.parse(location.search).q?.replace(sanitizeRegex, '')

	if (!searchParam) searchParam = ''

	const [loading, setLoading] = useState(false)
	const [firstLoading, setFirstLoading] = useState(true)
	const [page, setPage] = useState(1)
	const [totalFound, setTotalFound] = useState(0)
	const [totalPages, setTotalPage] = useState(0)
	const [categories, setCategories] = useState([])
	const [results, setResults] = useState(null)
	const [filters, setFilters] = useState([])
	const [rawInputSearch, setInputSearch] = useState(searchParam)
	const inputSearch = useDebounce(rawInputSearch, 500)
	const [filtersOpen, setFiltersOpen] = useState(false)
	const [windowDimensions, setWindowDimensions] = useState({
		width: typeof window !== 'undefined' && window.innerWidth,
		height: typeof window !== 'undefined' && window.innerHeight
	})

	const sanitizeIndex = string => {
		let str = string.replace(new RegExp('_', 'g'), '-').replace(`${process.env.GATSBY_COSMIC_BUCKET}-`, '')
		str = str.replace(str.substring(str.length - 14), '')

		return str
	}
	/**
	 * Obtém parametro de busca da URL:
	 * Side effect que verifica sempre que a URL é alterada, ou seja, quando há
	 * um novo input na busca da NavBar
	 */

	useEffect(() => {
		setInputSearch(searchParam)
	}, [searchParam])

	useEffect(() => {
		navigate(`/busca?q=${inputSearch}`)
	}, [inputSearch])

	/**
	 * Consulta no ElasticSearch:
	 * Side effect que faz uma nova requisição via GET no ElasticSearch
	 * a cada atualização de 'querySearch';
	 */
	useEffect(() => {
		const fetchData = async () => {
			setPage(1)
			setLoading(true)

			try {
				const data = await axios.post('/elasticsearch/digital/free-search', {
					search: {
						query: {
							multi_match: {
								query: searchParam,
								type: 'phrase_prefix'
							}
						},
						aggs: {
							byindex: {
								terms: {
									size: 15,
									field: '_index'
								},
								aggs: {
									grades_stats: {
										max: {
											script: '_score'
										}
									}
								}
							}
						}
					},
					//index: `${process.env.GATSBY_COSMIC_BUCKET}-*`
					index: getIndexes()
				})

				const buckets = []
				for (let i = 0; i < data.data.aggregations.byindex.buckets.length; i++) {
					let bucket = sanitizeIndex(data.data.aggregations.byindex.buckets[i].key)
					if (process.env.NODE_ENV === 'development' || process.env.GATSBY_ACTIVE_ENV === 'development') {
						buckets.push({
							key: BUCKET_LIST[bucket],
							val: data.data.aggregations.byindex.buckets[i].doc_count,
							index: data.data.aggregations.byindex.buckets[i].key
						})
					} else if (BUCKET_LIST[bucket]) {
						buckets.push({
							key: BUCKET_LIST[bucket],
							val: data.data.aggregations.byindex.buckets[i].doc_count,
							index: data.data.aggregations.byindex.buckets[i].key
						})
					}
				}
				setCategories(groupBy(buckets, 'key'))
			} catch {
				setPage(1)
			}
		}
		fetchData()
	}, [searchParam])

	/**
	 * Consulta no ElasticSearch:
	 * Side effect que faz uma nova requisição via GET no ElasticSearch
	 * a cada atualização de 'querySearch';
	 */
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			// let newUrl = urlSearch
			let indexFilters = []

			if (filters.length > 0) {
				// let filterString = ''
				// for (let i = 0; i < filters.length; i++) {
				// 	const filterText = Object.keys(BUCKET_LIST).find(key => BUCKET_LIST[key] === filters[i])
				// 	if (i === 0) {
				// 		filterString += `${process.env.GATSBY_COSMIC_BUCKET}-${filterText}`
				// 	} else {
				// 		filterString += `,${process.env.GATSBY_COSMIC_BUCKET}-${filterText}`
				// 	}
				// }

				// // newUrl = URL_ELASTIC(`/${filterString}/_search`)
				// eslint-disable-next-line array-callback-return
				filters.map(f => {
					indexFilters.push(categories[f][0].index)
				})
			}

			const initFrom = (page - 1) * 10

			// ****** ******
			// Filtro aplicado para exame 6983 para amais PI
			// após ajuste pelo back o filtro deverá ser retirado
			// ****** ******
			try {
				const queries =
					process.env.COSMIC_BUCKET === 'amaispi'
						? filters.length > 0
							? {
									search: {
										from: initFrom,
										highlight: {
											fields: {
												'*': {}
											},
											pre_tags: ['<strong>'],
											post_tags: ['</strong>'],
											boundary_scanner_locale: 'pt-BR'
										},
										query: {
											bool: {
												must: { multi_match: { query: searchParam, type: 'phrase_prefix' } },
												filter: { terms: { _index: indexFilters } },
												must_not: [
													{
														term: {
															id: 6983
														}
													}
												]
											}
										}
									},
									index: getIndexes()
							  }
							: {
									search: {
										query: {
											bool: {
												must: [
													{
														multi_match: {
															query: searchParam,
															type: 'phrase_prefix'
														}
													}
												],
												must_not: [
													{
														term: {
															id: 6983
														}
													}
												]
											}
										},
										from: initFrom,
										highlight: {
											fields: {
												'*': {}
											},
											pre_tags: ['<strong>'],
											post_tags: ['</strong>'],
											boundary_scanner_locale: 'pt-BR'
										}
									},
									index: getIndexes()
							  }
						: filters.length > 0
						? {
								search: {
									from: initFrom,
									highlight: {
										fields: {
											'*': {}
										},
										pre_tags: ['<strong>'],
										post_tags: ['</strong>'],
										boundary_scanner_locale: 'pt-BR'
									},
									query: {
										bool: {
											must: { multi_match: { query: searchParam, type: 'phrase_prefix' } },
											filter: { terms: { _index: indexFilters } }
										}
									}
								},
								index: getIndexes()
						  }
						: {
								search: {
									query: {
										bool: {
											must: [
												{
													multi_match: {
														query: searchParam,
														type: 'phrase_prefix'
													}
												}
											]
										}
									},
									from: initFrom,
									highlight: {
										fields: {
											'*': {}
										},
										pre_tags: ['<strong>'],
										post_tags: ['</strong>'],
										boundary_scanner_locale: 'pt-BR'
									}
								},
								index: getIndexes()
						  }

				const data = await axios.post('/elasticsearch/digital/free-search', queries)
				let pages = data.data.hits.total.value / 10

				if (data.data.hits.total.value % 10 > 0) {
					pages += 1
				}
				setTotalFound(data.data.hits.total.value)
				const dataResults = data.data.hits.hits
				if (Array.isArray(dataResults) && dataResults.length) {
					setTotalPage(parseInt(pages))
					setResults(dataResults)
				} else {
					setResults(null)
				}
				setLoading(false)
				setFirstLoading(false)
			} catch {
				setLoading(false)
				setFirstLoading(false)
			}
		}
		fetchData()
	}, [page, searchParam, filters, categories])

	/**
	 * Dimensões da janela:
	 * Side effect que verifica dimensões da janela e é utilizado na diferenciação de
	 * funcionalidades entre mobile e desktop (Filtros)
	 */
	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions({
				width: typeof window !== 'undefined' && window.innerWidth,
				height: typeof window !== 'undefined' && window.innerHeight
			})
		}
		handleResize()
	}, [])

	const handleNextPage = (selectedPage, pageSize) => {
		setPage(selectedPage)
		return []
	}

	/**
	 * Atualiza array de filtros:
	 * Ao clicar em um checkbox (marcar), o valor de 'name' dele é pego e inserido no array
	 * Se clicar para desmarcar, um filter é executado excluindo este valor do array
	 */
	const updateFilters = e => {
		setPage(1)
		if (e.target.checked) {
			setFilters([...filters, e.target.name])
		} else {
			setFilters(filters.filter(item => item !== e.target.name))
		}
	}

	/**
	 * Atualiza estado necessário (querySearch) para realizar nova consulta no Elastic:
	 * Função criada para evitar que seja realizada uma nova requisição a cada letra
	 * que o usuário digita no campo de busca...
	 */
	const submitSearch = e => {
		e.preventDefault()
		navigate(`/busca?q=${inputSearch}`)

		//Tira o foco do input pro teclado do mobile desaparecer
		e.target.blur()
	}

	const mode = isMobile ? 'mobile' : 'desktop'
	return (
		<>
			{loading && <Loading />}
			<MainContainer>
				<BreadcrumbAuto title='busca' />
				<MainTitleWrapper>
					<MainTitle> Busca </MainTitle>
				</MainTitleWrapper>
				<Grid>
					<Sidebar>
						<HelperText> Você buscou </HelperText>
						<SearchForm onSubmit={submitSearch}>
							<SearchButton type='submit'>
								<SearchIcon />
							</SearchButton>
							<SearchInput
								type='text'
								name='innerSearch'
								placeholder='Pesquisar...'
								value={rawInputSearch}
								onChange={e => setInputSearch(e.target.value?.replace(sanitizeRegex, ''))}
							/>
						</SearchForm>
						{/*
						 * "results !== null && results.length > 0"
						 * Expressão que exibe tela de filtros apenas se o array de resultados
						 * não for nulo e tiver pelo menos 1 item
						 */}
						{results !== null && results.length > 0 && (
							<>
								<Separator />
								<FiltersWrapper open={filtersOpen}>
									<HelperText> Filtre por: </HelperText>
									<Filters windowDimensions={windowDimensions} updateFilters={updateFilters} categories={categories} />
									<MediaQuery maxDeviceWidth={1224}>
										<FiltersFooter>
											<p className='totalResults'>
												{`${(results !== null &&
													results.length > 0 &&
													results.filter(result =>
														filters.length ? filters.includes(BUCKET_LIST[sanitizeIndex(result._index)]) : true
													).length) ||
													0} resultados`}
											</p>
											<div className='actions'>
												<button className='toCancel' onClick={() => setFiltersOpen(!filtersOpen)}>
													Cancelar
												</button>
												<button className='toFilter' onClick={() => setFiltersOpen(!filtersOpen)}>
													Filtrar ({filters.length})
												</button>
											</div>
										</FiltersFooter>
									</MediaQuery>
								</FiltersWrapper>
							</>
						)}
					</Sidebar>
					<MediaQuery maxDeviceWidth={1224}>
						<FilterActionsMobile>
							<p className='results'>
								{' '}
								{results !== null && results.length > 0
									? results.filter(result =>
											filters.length ? filters.includes(BUCKET_LIST[sanitizeIndex(result._index)]) : true
									  ).length
									: '0'}{' '}
								resultados{' '}
							</p>
							{results !== null && results.length > 0 && (
								<button className='filter' onClick={() => setFiltersOpen(!filtersOpen)}>
									Filtrar ({filters.length})
								</button>
							)}
						</FilterActionsMobile>
					</MediaQuery>
					<div className='results'>
						{results !== null && results.length > 0 && (
							<ResultsCounterDesktop> {totalFound} resultados encontrados </ResultsCounterDesktop>
						)}

						{results !== null && !firstLoading ? (
							<Pagination
								elementToRender={SearchCard}
								elementsPerPage={10}
								handleNextPage={handleNextPage}
								totalPages={totalPages}
								mode={mode}
								items={results}
								page={page}
								needFetch={true}
							/>
						) : null}
						{results !== null || loading || firstLoading ? null : (
							<NoResultsFound>
								<p className='title'> Nenhum resultado encontrado </p>
								<p className='text'>
									Sua busca por '{inputSearch}' não retornou resultados. Tente novamente com outros termos.
								</p>
							</NoResultsFound>
						)}
					</div>
				</Grid>
			</MainContainer>
		</>
	)
}
