import React from 'react'
import styled from 'styled-components'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Row = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
`

const Item = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	&:nth-child(1) {
		background-color: ${props => props.theme.colors.primary};
		flex: 1.5;

		@media (max-width: 767.98px) {
			flex: auto;
			min-height: 100px;
		}

		@media (min-width: 768px) and (max-width: 991.98px) {
			flex: unset;
			width: 100%;
		}

		@media (min-width: 992px) and (max-width: 1199.98px) {
			flex: 1;
			min-height: 100px;
		}
	}

	&:nth-child(2) {
		background-color: #0093cd;
		flex: 1.5;
		padding: 0 10px;

		@media (max-width: 767.98px) {
			flex: auto;
			min-height: 100px;
		}

		@media (min-width: 768px) and (max-width: 991.98px) {
			flex: unset;
			width: 100%;
		}

		@media (min-width: 992px) and (max-width: 1199.98px) {
			flex: 1;
			min-height: 100px;
		}
	}

	&:nth-child(3) {
		background-color: #0087bc;
		flex: 1.5;

		@media (max-width: 767.98px) {
			flex: auto;
			min-height: 100px;
		}

		@media (min-width: 768px) and (max-width: 991.98px) {
			flex: unset;
			width: 100%;
		}

		@media (min-width: 992px) and (max-width: 1199.98px) {
			flex: 1;
			min-height: 100px;
		}
	}
`

const Icon = styled.div`
	width: 50px;
	height: 50px;
`

const Img = styled.img`
	width: 100%;
	height: 100%;
`

const Text = styled.div`
	padding: 10px 20px;

	@media (max-width: 320px) {
		padding: 12px;
	}
`

const Title = styled.div`
	margin: 0;
	color: ${props => props.theme.colors.white};
	font-size: 16px;
`

export default function ServicebarLandingPage({ cosmicjsLandingPages }) {
	return (
		<Row>
			{cosmicjsLandingPages &&
				cosmicjsLandingPages.metadata.barra_de_informacoes.map(item => (
					<Item key={item.texto}>
						<Icon>
							<Img src={imgixUrlOptimizerAuto(item.icone.imgix_url)}></Img>
						</Icon>
						<Text>
							<Title dangerouslySetInnerHTML={{ __html: item.texto }}></Title>
						</Text>
					</Item>
				))}
		</Row>
	)
}
