const getBrandId = brand => {
	let brandId = 0

	switch (brand) {
		case 'Amais SP':
			brandId = 17
			break
		case 'Amais PE':
			brandId = 3
			break
		case 'Amais PR':
			brandId = 9
			break
		case 'Amais DF':
			brandId = 23
			break
		case 'Diagnoson':
			brandId = 5
			break
		case 'IRN':
			brandId = 26
			break
		case 'Labs amais':
			brandId = 22
			break
		default:
			console.log('Marca não encontrada')
	}

	return brandId
}

export default getBrandId
