import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Slider from 'react-slick'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const CustomSlider = styled.div`
	@media (min-width: 1200px) {
		width: 85%;
		margin: 0 auto;
	}
`

const Grid = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	@media (min-width: 1200px) {
		width: 85%;
		display: grid;
		padding: 40px 10px;
		margin: 0 auto;
	}

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		padding: 20px 10px 0;
		width: 92%;
		padding: 0;
		margin: 0 auto;
	}

	@media (min-width: 768px) and (max-width: 1199.98px) {
		padding: 20px 0;
	}

	.slick-slider {
		@media (min-width: 991.98px) {
			max-width: 85%;
			margin: 0 auto;
		}

		.slick-arrow {
			display: none !important;
		}
	}
`

const TitleBeneficios = styled.p`
	margin: 0 auto;
	position: relative;
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: center;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 90px;
		height: 5px;
		left: 50%;
		transform: translateX(-50%);
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
			left: 0;
			transform: translateX(0);
		}
	}

	@media (max-width: 767.98px) {
		font-size: 28px;
		margin: 0;
		text-align: left;
	}
`

const TextBeneficios = styled.div`
	padding-top: 40px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: center;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	@media (max-width: 991.98px) {
		line-height: 1.4;
		font-size: 15px;
		text-align: justify;
	}
`

const Image = styled.img`
	max-height: 200px;
	border-radius: 5px;
	margin: 0 auto;

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-height: 140px;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		max-height: 160px;
	}
`

const TextWrapper = styled.div`
	@media (max-width: 991.98px) {
		display: flex;
		flex-direction: column;
		max-width: 647px;
		width: 100%;
		margin: 20px auto;
	}

	color: ${theme('colors.gray66')};
`
const CardCustom = styled.div`
	margin-top: 40px;
	@media (max-width: 767.98px) {
		display: flex;
		align-items: center;
		width: 90%;
		max-width: 300px;
	}
`

const CardBody = styled.div`
	padding: 0;
	padding-top: 12px;
`

const CardText = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	text-align: center;
	color: ${props => props.theme.colors.gray66};
	padding: 0 30px;

	@media (max-width: 767.98px) {
		padding: 10px 20px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		padding: 0 10px;
	}
`

const settings = {
	className: 'center',
	centerMode: false,
	infinite: false,
	slidesToShow: 3,
	speed: 500,
	centerPadding: '50px',
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				centerMode: true,
				infinite: true,
				dots: false
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
				centerMode: true,
				infinite: true,
				centerPadding: 15,
				dots: false
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
				infinite: true,
				centerPadding: 15,
				dots: false
			}
		}
	]
}

export default function BeneficiosLandingPage({ cosmicjsLandingPages }) {
	return (
		<Grid>
			<TextWrapper>
				<TitleBeneficios id='title-benefits-landing'>{cosmicjsLandingPages.metadata.secao2.titulo}</TitleBeneficios>
				<TextBeneficios dangerouslySetInnerHTML={{ __html: cosmicjsLandingPages.metadata.secao2.descricao }} />
			</TextWrapper>
			<CustomSlider>
				<Slider {...settings}>
					{cosmicjsLandingPages &&
						cosmicjsLandingPages.metadata.secao2.carrossel.map(item => (
							<CardCustom key={item.texto}>
								<Image src={imgixUrlOptimizerAuto(item.imagem.imgix_url, undefined, 200)} />
								<CardBody>
									<CardText>{item.texto}</CardText>
								</CardBody>
							</CardCustom>
						))}
				</Slider>
			</CustomSlider>
		</Grid>
	)
}
